import React from 'react'
import styled from '@emotion/styled'

const Link = styled.a({
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit'
})

const BoldLink = ({children, ...otherProps}) => {
    return (
        <Link {...otherProps}>
            {children}
        </Link>
    )
}

export default BoldLink;
