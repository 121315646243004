import React from 'react'
import { Text as RebassText } from 'rebass'

const commonStyles = {
}

const displayStyles = {
  ...commonStyles,
  fontSize: ['2.65rem', '2.65rem', '4.25rem'],
  lineHeight: [0.9, 0.9, 1.03],
}

const titleStyles = {
  ...commonStyles,
  fontSize: ['1rem', '1rem', '1.625rem'],
  lineHeight: [1, 1, 1.16],
}

const bodyStyles = {
  ...commonStyles,
  fontSize: ['0.625rem', '0.625rem', '1rem'],
  lineHeight: [1, 1, 1.25],
}

const getStyleType = (type) => {
  switch (type) {
    case 'display':
      return displayStyles
    case 'title':
      return titleStyles
    case 'body':
      return bodyStyles
    default:
      return titleStyles
  }
}

const Text = ({
  type = 'title',
  variant = 'primary',
  opacity = 1,
  children,
  ...otherProps
}) => {
  const styles = getStyleType(type)
  return (
    <RebassText sx={styles} css={{opacity}} variant={variant} {...otherProps}>
      {children}
    </RebassText>
  )
}
export default Text
