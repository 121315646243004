export default {
  breakpoints: ['320px', '768px', '1024px'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 68],
  colors: {
    primary: 'white',
    secondary: '#121D32',
    blue: '#07c',
    error: '#FF3D00',
    lightgray: '#f6f6ff',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {},
  text: {
    primary: {
      color: 'secondary',
    },
    secondary: {
      color: 'primary',
    },
    grey: {
      color: '#C0BFBF',
    },
  },
  buttons: {
    primary: {
      color: 'secondary',
      bg: 'primary',
      borderColor: 'secondary',
      ':hover': {
        bg: ['primary', 'primary', 'secondary'],
        color: ['secondary', 'secondary', 'primary'],
        borderColor: ['secondary', 'secondary', 'primary'],
      },
    },
    secondary: {
      color: 'primary',
      bg: 'secondary',
      borderColor: 'primary',
      ':hover': {
        bg: ['secondary', 'secondary', 'primary'],
        color: ['primary', 'primary', 'secondary'],
        borderColor: ['primary', 'primary', 'secondary'],
      },
    },
    disabled: {
      color: '#121d32b0',
      bg: '#c3c3c352',
      borderColor: '#121d32b0',
      ':hover': {
        bg: '#c3c3c352',
        color: '#121d32b0',
        borderColor: '#121d32b0',
      },
    },
  },
};
