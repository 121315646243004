import React from 'react';
import styled from '@emotion/styled';

const DummyCanvas = () => (
  <DummyCanvasFrame>
    <DummyRow height={2}>
      <DummyColumn>
        <DummyBox />
      </DummyColumn>
      <DummyColumn>
        <DummyBox />
        <DummyBox />
      </DummyColumn>
      <DummyColumn>
        <DummyBox />
      </DummyColumn>
      <DummyColumn>
        <DummyBox />
        <DummyBox />
      </DummyColumn>
      <DummyColumn>
        <DummyBox />
      </DummyColumn>
    </DummyRow>
    <DummyRow>
      <DummyBox />
      <DummyBox />
    </DummyRow>
  </DummyCanvasFrame>
);

/**
 * Create dummy rows and columns to not modify the correct behaviour of the rows/columns on mobile.
 * This will change soon when we implement CSS Grid
 */

const DummyRow = styled.div(({ height = 1 }) => ({
    display: "flex",
    flex: height,
    flexDirection: "row",
}));

const DummyColumn = styled.div(({ width = 1 }) => ({
    display: "flex",
    flex: width,
    flexDirection: 'column'
}));

const DummyBox = styled.div(() => ({
  display: 'flex',
  flex: 1,
  border: '1px solid #EEE',
}));

const DummyCanvasFrame = styled.div(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
}));

export default DummyCanvas;
