import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled/macro'
import PropTypes from 'prop-types'
import { devices } from '../constants/devices'

const Navbar = ({ style, position, children }) => {
  const [borderColor, setBorderColor] = useState('transparent')
  useEffect(() => {
    const e = window.addEventListener('scroll', () => {
      if (window.scrollY > position) {
        setBorderColor('#121D32')
      } else {
        setBorderColor('transparent')
      }

      return () => window.removeEventListener('scroll', e)
    })
  })
  return (
    <Container color={borderColor} style={style}>
      {children}
    </Container>
  )
}

const Container = styled.div(({ color }) => ({
  padding: 10,
  width: '100%',
  position: 'sticky',
  top: 20,
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  border: `1px solid ${color}`,
  [`${devices.laptop}`]: {
    padding: 20,
  },
}))

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Navbar
