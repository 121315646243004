
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Button from './Button';
import theme from '../constants/theme';

const ButtonGroup = ({ buttons }) => (
  buttons.map((button, index) => {
    const { placeholder, ...otherProps } = button;
    if (index === 0) {
      return (
        <FirstButtonGroupItem
          key={placeholder}
          {...otherProps}
        >
          {placeholder}
        </FirstButtonGroupItem>
      );
    }
    if (index === buttons.length - 1) {
      return (
        <LastButtonGroupItem
          key={placeholder}
          {...otherProps}
        >
          {placeholder}
        </LastButtonGroupItem>
      );
    }
    return (
      <ButtonGroupItem {...otherProps} key={placeholder}>
        {placeholder}
      </ButtonGroupItem>
    );
  })
);

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};

const FirstButtonGroupItem = styled(Button)(() => ({
  borderBottomLeftRadius: '62,5rem',
  borderTopLeftRadius: '62,5rem',
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  ':hover': {
    borderColor: theme.colors.secondary,
  },
}));

const LastButtonGroupItem = styled(Button)(() => ({
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
  borderBottomRightRadius: '62,5rem',
  borderTopRightRadius: '62,5rem',
  ':hover': {
    borderColor: theme.colors.secondary,
  },
}));

const ButtonGroupItem = styled(Button)(() => ({
  borderRadius: 0,
  ':hover': {
    borderColor: theme.colors.secondary,
  },
}));

export default ButtonGroup;
