import firebase from 'firebase/app';
import { useEffect, useState, useCallback } from 'react';

const useFirebase = (path) => {
  const [data, setData] = useState();
  useEffect(() => {
    const ref = firebase.database().ref(path);
    ref.on('value', (snapshot) => setData(snapshot.val()));
    return () => ref.off('value');
  }, [path]);
  const set = useCallback((value) => firebase
    .database()
    .ref(path)
    .set(value));
  return [data, set];
};

export default useFirebase;
