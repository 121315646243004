import React from 'react'
import styled from '@emotion/styled'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Navbar from '../components/Navbar'
import Button from '../components/Button'
import { canvasTypes } from '../../variables'
import CanvasPreview from '../components/CanvasPreview'
import Text from '../components/Text'
import Theme from '../constants/theme'
import { devices } from '../constants/devices'

import {
  userIdSelector,
  anonSelector,
  displayNameSelector,
} from '../../auth/authSelectors'
import { createCanvas } from '../firebase'
import useScrollTo from '../hooks/useScrollTo'
import HomeMenu from '../components/HomeMenu'
import ContactUs from '../components/ContactUs'

const HomePage = ({ userId }) => {
  const [scrollToPreviews, previewsSection] = useScrollTo()
  const [scrollToFeatures, featuresSection] = useScrollTo()
  const [scrollToAbout, aboutSection] = useScrollTo()
  const location = useLocation()
  const history = useHistory()
  const redirectSignin = {
    pathname: '/signin',
    state: { background: location },
  }
  return (
    <>
      <Container>
        <Navbar position="20">
          <HomeMenu
            scrollToPreviews={scrollToPreviews}
            scrollToFeatures={scrollToFeatures}
            scrollToAbout={scrollToAbout}
            redirectSignin={redirectSignin}
          />
        </Navbar>
        <WithBackgroundImage>
          <DescriptionContainer>
            <HomeText type="display">
              Easily create, share, and collaborate in real-time for free.
            </HomeText>
            <ButtonsContainer>
              <Link to="/library">
                <Button
                  css={{ marginRight: 13 }}
                  type="large"
                  variant="secondary"
                >
                  create your first canvas
                </Button>
              </Link>
              <Button onClick={() => scrollToAbout(0)} type="large">learn more</Button>
            </ButtonsContainer>
          </DescriptionContainer>
        </WithBackgroundImage>
        <PreviewsSection ref={previewsSection}>
          {Object.entries(canvasTypes).map(([key, data], index) => (
            <ContentContainer index={index} key={key}>
              <CanvasPreview
                title={data.name}
                about={data.about}
                createAction={() => {
                  createCanvas(key, userId, (id) => {
                    history.push(`/canvas/${id}`)
                  })
                }}
              />
            </ContentContainer>
          ))}
        </PreviewsSection>
        <DescriptionContainer ref={featuresSection}>
          <DisplayTitle type="display">
            Access everything for free.
          </DisplayTitle>
          <DisplayContent>
            <DisplayDescription type="title">
              Unlimited sharing
            </DisplayDescription>
            <DisplayDescription type="title">
              Real-time sharing
            </DisplayDescription>
            <DisplayDescription type="title">Auto-save</DisplayDescription>
            <DisplayDescription type="title">4 canvas types</DisplayDescription>
            <DisplayDescription type="title">
              No signup required
            </DisplayDescription>
            <DisplayDescription type="title">
              4 looks to choose from
            </DisplayDescription>
            <DisplayDescription type="title">
              Accessible on any devices
            </DisplayDescription>
            <DisplayDescription type="title">Browser based</DisplayDescription>
          </DisplayContent>
        </DescriptionContainer>
        <DescriptionContainer>
          <DisplayTitle type="display">Make your first canvas!</DisplayTitle>
          <ButtonsContainer>
            <Link to="/library">
              <Button
                css={{ marginRight: 13 }}
                type="large"
                variant="secondary"
              >
                create your first canvas
              </Button>
            </Link>
          </ButtonsContainer>
        </DescriptionContainer>
        <AboutSection ref={aboutSection}>
          <AboutThis>
            <Text variant="secondary">About</Text>
            <AboutThisTextContainer>
              <AboutThisParagraph variant="secondary" type="body">
                It’s in the name, our goal was to create the most simple and
                accessible online tool to; make, share, and collaborate with
                others - to explore, plan and develop business or personal ideas
                via the canvas format.
              </AboutThisParagraph>
              <AboutThisParagraph variant="secondary" type="body">
                Built with the latest web technologies, this app is the fastest
                and most accessible canvas tool around. Featuring real time collaboration 
                capabilities that allow you to see any changes from your collaborators instantly.  
              </AboutThisParagraph>
              <AboutThisParagraph variant="secondary" type="body">
                We created the Simple Canvas App because we wanted to
                collaborate with team mates on a Lean Canvas online, but could
                not find anything that was simple to use, fast, and easy to access for free. Enjoy! 
              </AboutThisParagraph>
            </AboutThisTextContainer>
          </AboutThis>
          <ContactUs />
        </AboutSection>
        <Footer>
          <Text type="title">A free community project by <P22Link href="https://p22.studio/" target="_blank">p22.studio</P22Link></Text>
        </Footer>
      </Container>
    </>
  )
}

const P22Link = styled.a({
  color: '#121D32'
})

const Footer = styled.footer({
  marginTop: 20,
  paddingLeft: '0.625rem',
  height: '40rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [`${devices.tablet}`]: {
    paddingLeft: '1.25rem',
    height: '56.25rem',

  }
});

const DisplayDescription = styled(Text)(() => ({
  flex: 1,
  flexBasis: '25%',
  textAlign: 'center',
  marginTop: 40,
  [`${devices.tablet}`]: {
    marginTop: 80,
  },
}))

const DisplayTitle = styled(Text)(() => ({
  display: 'flex',
  marginTop: 174,
  textAlign: 'center',
}))

const HomeText = styled(Text)(() => ({
  width: '70%',
  textAlign: 'center',
}))

const ButtonsContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: 60,
}))

const DescriptionContainer = styled.div(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}))

const DisplayContent = styled.div(() => ({
  display: 'flex',
  flexFlow: 'wrap',
  flexDirection: 'column',
  [`${devices.laptop}`]: {
    flexDirection: 'row',
  },
}))

const PreviewsSection = styled.div({
  display: 'flex',
  justifyContent: 'space-evenly',
  flexWrap: 'wrap',
  flexDirection: 'column',
  [`${devices.laptop}`]: {
    flexDirection: 'row',
  },
})

const AboutSection = styled.div({
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-evenly',
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: -20,
  marginLeft: -20,
  marginTop: 300,
  marginBottom: -20,
  paddingTop: 260,
  backgroundColor: Theme.colors.secondary,
  [`${devices.laptop}`]: {
    flexDirection: 'row',
    paddingTop: 460,
  },
})

const AboutSquare = styled.div({
  flex: 1,
  height: 420,
  border: '1px solid #FFFFFF',
  boxSizing: 'border-box',
  borderRadius: 2,
  padding: 20,
})

const AboutThis = styled(AboutSquare)(() => ({
  [`${devices.laptop}`]: {
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const AboutThisTextContainer = styled.div({
  marginTop: 60,
})

const AboutThisParagraph = styled(Text)(() => ({
  marginTop: 15,
}))

const Container = styled.div({
  padding: 20,
})

const ContentContainer = styled.div(({ index }) => {
  if (index % 2 === 0) {
    return {
      [`${devices.laptop}`]: {
        marginRight: 10,
        flex: '1 40%',
      },
    }
  }
  return {
    [`${devices.laptop}`]: {
      marginLeft: 10,
      flex: '1 40%',
    },
  }
})

const WithBackgroundImage = styled.div({
  width: '100%',
  paddingBottom: 310,
  paddingTop: 230,
  backgroundRepeat: 'no-repeat',
  backgroundImage: "url('/images/home_background.svg')",
})

export default connect((state) => ({
  userId: userIdSelector(state),
  displayName: displayNameSelector(state),
  anon: anonSelector(state),
}))(HomePage)
