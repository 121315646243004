import { useEffect } from 'react'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-122392647-2')

export const useTrackPageview = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [ReactGA, window.location.pathname, window.location.search])
}
