import React from "react";
import PropTypes from "prop-types";
import { Button as RebassButton } from "rebass";

const commonStyles = {
  fontFamily: "inherit",
  cursor: "pointer",
  textTransform: "lowercase",
  border: "1px solid",
  borderRadius: "62.5rem",
  letterSpacing: "-0.01em",
  whiteSpace: "nowrap",
  ":focus": { outline: "none" },
  transition: "all .2s",
};

const largeStyles = {
  ...commonStyles,
  padding: [".6875rem", ".6875rem", "1.25rem"],
  fontSize: ["1rem", "1rem", "1.625rem"],
  lineHeight: [1, 1, 1.16],
};

const smallStyles = {
  ...commonStyles,
  padding: ["0.125rem 0.3125rem", "0.125rem 0.3125rem", "0.25rem .6875rem"],
  fontSize: ["0.625rem", "0.625rem", "1rem"],
  lineHeight: [1, 1, 1.25],
};

const getStyleType = (type) => (type === "large" ? largeStyles : smallStyles);

const Button = React.forwardRef(
  ({ type, variant = "primary", ...otherProps }, ref) => {
    const styles = getStyleType(type);
    return (
      <RebassButton ref={ref} variant={variant} sx={styles} {...otherProps} />
    );
  }
);

Button.propTypes = {
  type: PropTypes.oneOf(["large", "small"]),
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

Button.defaultProps = {
  type: "small",
  variant: "primary",
};

export default Button;
