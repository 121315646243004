import authActions from './constants';

//User reducer
export const signIn = (userSignedIn) => ({ type: authActions.SIGN_IN, payload: userSignedIn })
export const signOut = () => ({ type: authActions.SIGN_OUT })
export const upgradeAnonUser = (userUpgraded) => ({
  type: authActions.UPGRADE_ANON,
  payload: userUpgraded,
})
export const setDisplayName = (newDisplayName) => ({
  type: authActions.UPDATE_DISPLAY_NAME,
  payload: newDisplayName,
})
