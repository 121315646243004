import { useSelector } from 'react-redux';
import useFirebase from './useFirebase';
import { userIdSelector } from '../../auth/authSelectors';
import { removeCanvasFromCollection } from '../firebase';

const useCanvasRetrieval = (canvasId, innerPath) => {
  const totalPath = innerPath
    ? `/canvases/${canvasId}/${innerPath}`
    : `/canvases/${canvasId}`;
  const [value, set] = useFirebase(totalPath);
  const userId = useSelector(userIdSelector);
  if (value === null) {
    removeCanvasFromCollection(canvasId, userId);
  }
  return [value, set];
};

export default useCanvasRetrieval;
