import firebase from "firebase/app";
import { upgradeAnonUser as upgradeAnonUserAction } from "../store/actions";

export const authGoogle = () => {
  const google = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithPopup(google);
};

export const sendPasswordResetEmail = (mail) => {
  firebase.auth().sendPasswordResetEmail(mail);
};

export const changePasswordUserLogged = async (
  currentPassword,
  newPassword
) => {
  const userLoggedEmail = firebase.auth().currentUser.email;
  const credentials = firebase.auth.EmailAuthProvider.credential(
    userLoggedEmail,
    currentPassword
  );
  try {
    await firebase.auth().currentUser.reauthenticateWithCredential(credentials);

    firebase.auth().currentUser.updatePassword(newPassword);
  } catch (e) {
    throw new Error(e.message);
  }
};

export const submitResetPassword = async (code, newPassword) => {
  try {
    await firebase.auth().confirmPasswordReset(code, newPassword);
  } catch (e) {
    throw new Error(e.code);
  }
};

export const authEmail = async (email, password) => {
  const anonUser = firebase.auth().currentUser;

  await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async (response) => {
      const anonUserData = await firebase
        .database()
        .ref(`users/${anonUser.uid}`)
        .once("value")
        .then((snapshot) => snapshot.val());

      // Get real user data
      const userData = await firebase
        .database()
        .ref(`users/${response.user.uid}`)
        .once("value")
        .then((snapshot) => snapshot.val());

      // write merged data
      await firebase
        .database()
        .ref(`users/${response.user.uid}`)
        .set({ ...anonUserData, ...userData });

      await anonUser.delete();
    })
    .catch((e) => {
      throw new Error(e.code);
    });
};

export const upgradeAnonUser = (dispatch) => async (
  email,
  password,
  displayName
) => {
  const credentials = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );
  const usercred = await firebase
    .auth()
    .currentUser.linkAndRetrieveDataWithCredential(credentials);

  await firebase.auth().currentUser.updateProfile({
    displayName,
  });

  dispatch(
    upgradeAnonUserAction({
      displayName,
      email: usercred.user.email,
      emailVerified: usercred.user.emailVerified,
      photoURL: usercred.user.photoURL,
      uid: usercred.user.uid,
      // phoneNumber: usercred.user.phoneNumber,
      isAnonymous: usercred.user.isAnonymous,
      providerData: usercred.user.providerData,
    })
  );
};

export const updateCurrentUserDisplayName = async (displayName) => {
  await firebase.auth().currentUser.updateProfile({
    displayName,
  });
};

export const logOut = () => {
  firebase.auth().signOut();
};

export const setLastViewedOnCanvas = (canvasId) => {
  const user = firebase.auth().currentUser;
  if (user) {
    firebase
      .database()
      .ref(`/users/${user.uid}/${canvasId}/dateLastViewed`)
      .set(firebase.database.ServerValue.TIMESTAMP);
  }
};

export const createCanvas = (
  canvasType,
  ownerId,
  redirectCallback = () => {}
) => {
  const newCanvas = { type: canvasType, ownerId };
  const id = firebase
    .database()
    .ref("/canvases/")
    .push(newCanvas, () => {
      setLastViewedOnCanvas(id);
      redirectCallback(id);
    }).key;
};

export const checkIfEmailExists = async (email) => {
  const response = await firebase.auth().fetchSignInMethodsForEmail(email);
  return response.some((method) => method === "password");
};

export const validateOwnership = (canvasId, userId) =>
  firebase
    .database()
    .ref(`/canvases/${canvasId}/ownerId`)
    .once("value")
    .then((owner) => owner.val() === userId);

export const addCanvasToUserCollection = async (canvasId, userId) => {
  if (userId) {
    return firebase.database().ref(`/users/${userId}/${canvasId}`).set({
      dateLastViewed: firebase.database.ServerValue.TIMESTAMP,
      shared: true,
    });
  }
  return new Error("User id was not provided");
};

export const removeCanvasFromCollection = async (canvasId, userId) => {
  if (userId) {
    return firebase.database().ref(`/users/${userId}/${canvasId}`).remove();
  }
  return new Error("User id was not provided");
};

export const deleteCanvas = async (canvasId) => {
  firebase.database().ref(`/canvases/${canvasId}`).remove();
  // TODO remove canvas from every user that has it within his/her collection
};
