import React, { useState } from 'react'
import styled from '@emotion/styled'
import Button from './Button'
import DummyCanvas from './DummyCanvas'
import Text from './Text'
import { devices } from '../constants/devices'

const CanvasPreview = ({ title, about, createAction }) => {
  const [showAbout, toggleShowAbout] = useState(false)
  return (
    <CanvasPreviewBox>
      <CanvasPreviewHeader>
        <Text>{title}</Text>
        <ButtonContainer>
          <MarginButton
            variant={showAbout ? 'secondary' : 'primary'}
            onClick={() => toggleShowAbout(!showAbout)}
          >
            about
          </MarginButton>
          <Button secondary onClick={createAction}>
            create
          </Button>
        </ButtonContainer>
      </CanvasPreviewHeader>
      <CanvasPreviewContent>
        {showAbout ? (
          <AboutText type="body">{about}</AboutText>
        ) : (
          <DummyCanvas />
        )}
      </CanvasPreviewContent>
    </CanvasPreviewBox>
  )
}

const CanvasPreviewBox = styled.div({
  padding: 10,
  marginTop: 10,
  marginBottom: 10,
  border: '1px solid black',
  flex: '1 40%',
  [`${devices.laptop}`]: {
    padding: 20,
  },
})

const CanvasPreviewContent = styled.div({
  marginTop: 40,
  display: 'flex',
  alignItems: 'flex-end',
  height: 160,
  [`${devices.tablet}`]: {
    height: 350,
  },

  [`${devices.laptop}`]: {
    height: 350,
  },
})

const CanvasPreviewHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const AboutText = styled(Text)({
  marginTop: 80,
})

const MarginButton = styled(Button)(() => ({
  marginRight: 5,
  [`${devices.laptop}`]: {
    marginRight: 10,
  },
}))

const ButtonContainer = styled.div({
  display: 'flex',
  flex: 'grow',
})

export default CanvasPreview
