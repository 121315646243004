import React from 'react'
import styled from '@emotion/styled'

import Text from './Text'
import Button from './Button'

import { devices } from '../constants/devices'
import { Flex } from 'rebass'
import { mailToUs } from '../utils'; 

const AboutSquare = styled.div({
  flex: 1,
  height: 160,
  boxSizing: 'border-box',
  borderRadius: 2,
  padding: 20,
  [`${devices.laptop}`]: {
    height: 420,
  },
})

const Container = styled(AboutSquare)(({ type }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: 10,
  border: type == 'secondary' ? '1px solid #FFFFFF' : '1px solid #121D32',

  [`${devices.laptop}`]: {
    // marginLeft: 10,
    marginTop: 0,
  },
}))

const Header = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 60,
  [`${devices.laptop}`]: {
    marginBottom: 0,
  },
}));

const ContactUs = ({ type = 'secondary' }) => {
  return (
    <Container type={type}>
      <Header>
        <Flex>
          <Text variant={type}>Custom Canvases</Text>
        </Flex>
        <Flex>
          <Button onClick={mailToUs} variant={type}>contact us</Button>
        </Flex>
      </Header>
      <Text variant={type} type="body">
        We currently provide access to the following canvases; Lean, Business
        Model, User Centred Design and Feature canvas. If you have your own canvas that you'd like to make available online we could make it for you. Get in touch!
      </Text>
    </Container>
  )
}

export default ContactUs
