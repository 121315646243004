import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Button from './Button'
import styled from '@emotion/styled'
import { devices } from '../constants/devices'
import Text from './Text'

const ContainerDesktop = styled.div({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  [`${devices.laptop}`]: {
    display: 'flex',
  },
})

const MenuTitle = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
})

const MenuOptionsContainer = styled.div({
  marginTop: 20,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& > *:not(:last-child)': {
    marginRight: '10px'
  }
});

const Container = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  [`${devices.laptop}`]: {
    display: 'none',
  },
})

const FlexLink = styled(Link)({
  display: 'flex',
  textDecoration: 'none',
  [`${devices.laptop}`]: {
    display: 'unset',
  },
})

const ButtonsContainerDesktop = styled.div({
  '& > *:not(:last-child)': {
    marginRight: '10px'
  }
})

const HomeMenu = ({
  scrollToPreviews,
  scrollToFeatures,
  redirectSignin,
}) => {
  const [showMenu, setShowMenu] = useState(false)

  const Menu = () => (
    <Container>
      <MenuTitle>
        <Text type="title">simple canvas app</Text>
        <Button
          variant={showMenu ? 'secondary' : 'primary'}
          onClick={() => setShowMenu((prevState) => !prevState)}
        >
          menu
        </Button>
      </MenuTitle>
      {showMenu && (
        <MenuOptionsContainer >
          <Buttons
            scrollToPreviews={scrollToPreviews}
            scrollToFeatures={scrollToFeatures}
            redirectSignin={redirectSignin}
          />
        </MenuOptionsContainer>
      )}
    </Container>
  )

  const MenuDesktop = () => (
    <ContainerDesktop>
      <Text type="title">simple canvas app</Text>
      <ButtonsContainerDesktop>
        <Buttons
          scrollToPreviews={scrollToPreviews}
          scrollToFeatures={scrollToFeatures}
          redirectSignin={redirectSignin}
        />
      </ButtonsContainerDesktop>
    </ContainerDesktop>
  )

  return (
    <>
      <Menu />
      <MenuDesktop />
    </>
  )
}

const Buttons = ({
  scrollToPreviews,
  scrollToFeatures,
  redirectSignin,
}) => (
  <>
    <Button onClick={() => scrollToPreviews(100)}>canvases</Button>
    <Button onClick={() => scrollToFeatures(100)}>features</Button>
    <FlexLink to={redirectSignin}>
      <Button>sign in</Button>
    </FlexLink>
    <FlexLink to={redirectSignin}>
      <Button >create account</Button>
    </FlexLink>
  </>
)

export default HomeMenu
