import React from 'react'

import { BsTypeH1, BsTypeH2, BsTypeH3 } from 'react-icons/bs'
import { GoListOrdered, GoListUnordered } from 'react-icons/go'
import { FaBold, FaItalic, FaStrikethrough, FaUnderline } from 'react-icons/fa'
import BoldLink from './app/components/BoldLink'
import Text from './app/components/Text'

export const canvasTypes = {
  lean: {
    name: 'Lean Canvas',
    about:
      'Created by Ash Maurya as an adaptation of the Business Model Canvas. Lean Canvas is entrepreneur-focused and designed for emerging businesses. Lean Canvas promises an actionable and entrepreneur-focused business plan. It focuses on problems, solutions, key metrics and competitive advantages.',
    license: () => (
      <Text opacity={0.5} type="body">
        Adapted from the business model canvas by Ash Maurya and licensed under
        the{' '}
        <BoldLink
          href="https://creativecommons.org/licenses/by-sa/3.0/legalcode"
          target="_blank"
        >
          Creative Commons Attribution-ShareAlike 3.0 Un-ported license.
        </BoldLink>
      </Text>
    ),
  },
  marketValidation: {
    name: 'Market Validation Canvas',
    about:
      'Created out of a need to gauge market interest and validate the viability of a new idea, the Market Validation Canvas is a tool used to capture and analyze feedback from 3 potential customers.',
    license: () => (
      <Text opacity={0.5} type="body">
        Created by{' '}
        <BoldLink href="https://p22.studio" target="_blank">
          P22 Studio
        </BoldLink>
      </Text>
    ),
  },
  businessModel: {
    name: 'Business Model Canvas',
    about:
      'The Business Model Canvas was proposed by Alexander Osterwalder based on his earlier book: Business Model Ontology. It outlines several prescriptions which form the building blocks for the activities. It enables both new and existing businesses to focus on operational as well as strategic management and marketing plans.',
    license: () => (
      <Text opacity={0.5} type="body">
        Created by Alexander Osterwalder from{' '}
        <BoldLink href="https://www.strategyzer.com/" target="_blank">
          strategyzer.com
        </BoldLink>{' '}
        and licensed under the{' '}
        <BoldLink
          href="https://creativecommons.org/licenses/by-sa/3.0/legalcode"
          target="_blank"
        >
          Creative Commons Attribution-ShareAlike 3.0 Unported License.
        </BoldLink>
      </Text>
    ),
  },
  userCentred: {
    name: 'User Centred Design Canvas',
    about:
      'Created out of a need for an easy and effective tool facilitating the user experience design process. The left side of the canvas focuses on the users, the right side on the business. Following the logical order of analysis, the canvas enables creating a one sentence summary in the form of a unique value proposition',
    license: () => (
      <Text opacity={0.5} type="body">
        Created by Alina Prelicz-Zawadzka and Leszek Zawadzki from{' '}
        <BoldLink href="https://therectangles.com/" target="_blank">
          therectangles.com
        </BoldLink>{' '}
        and licensed under the{' '}
        <BoldLink
          href="https://creativecommons.org/licenses/by-sa/4.0/"
          target="_blank"
        >
          Creative Commons Attribution-ShareAlike 4.0 International license
        </BoldLink>
      </Text>
    ),
  },
  feature: {
    name: 'Feature Canvas',
    about:
      'Created by Nikita Efimov, Senior Product manager at UXPressia, and an inspiring leader of the UX SPb – community of UX designers, user researchers, and information architects in Saint Petersburg.',
    license: () => (
      <Text opacity={0.5} type="body">
        Created by{' '}
        <BoldLink href="http://featurecanvas.com/" target="_blank">
          Nikita Efimov
        </BoldLink>{' '}
        and distributed under a{' '}
        <BoldLink
          href="https://creativecommons.org/licenses/by-sa/4.0/"
          target="_blank"
        >
          Creative Commons Attribution-ShareAlike 4.0 International License.
        </BoldLink>
      </Text>
    ),
  },
}

export const pricingTypes = {
  basic: {
    title1: 'basic.',
    title2: 'free.',
    features: [
      'unlimited sharing.',
      'collaborate in real-time.',
      'live updating, no need to save.',
      'remote collaboration.',
      'auto save.',
      'no sign-up required.',
      'browser based.',
      'accesible on any device.',
    ],
  },
  pro: {
    title1: 'pro canvas.',
    title2: '$1 per canvas.',
    featuresTitle: "everything in 'free' plus:",
    features: [
      "share 'edit' links that allow others to add and edit content on your canvas.",
      'style you canvas, change the theme for a different look.',
    ],
  },
  subscription: {
    title1: 'subscription.',
    title2: '$12 per month.',
    features: [
      'create, share and receive unlimited canvases.',
      "access every feature - all canvases are 'pro'.",
    ],
  },
}

export const editorControls = {
  inlineStyles: [
    { key: 'BOLD', icon: FaBold },
    { key: 'ITALIC', icon: FaItalic },
    { key: 'UNDERLINE', icon: FaUnderline },
    { key: 'STRIKETHROUGH', icon: FaStrikethrough },
  ],
  blockStyles: [
    { key: 'header-one', type: 'HEADER', icon: BsTypeH1 },
    { key: 'header-two', type: 'HEADER', icon: BsTypeH2 },
    { key: 'header-three', type: 'HEADER', icon: BsTypeH3 },
    { key: 'unordered-list-item', type: 'LIST', icon: GoListUnordered },
    { key: 'ordered-list-item', type: 'LIST', icon: GoListOrdered },
  ],
  colors: {
    black: 'rgba(0, 0, 0, 1.0)',
    grey: 'rgba(128, 128, 128, 1.0)',
    red: 'rgba(255, 0, 0, 1.0)',
    orange: 'rgba(255, 127, 0, 1.0)',
    // yellow: 'rgba(180, 180, 0, 1.0)',
    green: 'rgba(0, 180, 0, 1.0)',
    blue: 'rgba(0, 0, 255, 1.0)',
    violet: 'rgba(127, 0, 255, 1.0)',
  },
}
