const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const devices = {
  mobile: `@media screen and (min-width: ${size.mobileS})`,
  tablet: `@media screen and (min-width: ${size.tablet})`,
  laptop: `@media screen and (min-width: ${size.laptop})`,
}

export const devicesHook = {
  mobile: `only screen and (min-width: ${size.mobileS})`,
  tablet: `only screen and (min-width: ${size.tablet})`,
  laptop: `only screen and (min-width: ${size.laptop})`,
}
