import { useRef } from 'react';

const useScrollTo = () => {
  // Where to scroll
  const targetRef = useRef(null);

  const scroll = (extraDistance) => {
    const y = extraDistance || 0;
    window.scrollTo(
      {
        top: targetRef.current.offsetTop - y,
        behavior: 'smooth',
      },
    );
  };

  return [scroll, targetRef];
};

export default useScrollTo;
