import React from 'react'
import styled from '@emotion/styled'
import DummyCanvas from './DummyCanvas'
import theme from '../constants/theme'
import { devices } from '../constants/devices'

const UserCanvasPlaceholder = () => (
  <UserCanvasBox>
    <UserCanvasContent>
      <DummyCanvas />
    </UserCanvasContent>
  </UserCanvasBox>
)

const UserCanvasBox = styled.div({
  padding: 20,
  marginTop: 10,
  marginBottom: 10,
  border: `1px dashed ${theme.colors.secondary}`,
})

const UserCanvasContent = styled.div({
  marginTop: 40,
  display: 'flex',
  alignItems: 'flex-end',
  height: 160,
  [`${devices.tablet}`]: {
    height: 350,
  },

  [`${devices.laptop}`]: {
    height: 350,
  },
})

export default UserCanvasPlaceholder
