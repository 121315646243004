export const userIdSelector = (state) =>
  state.user ? state.user.uid : state.user;

export const displayNameSelector = (state) =>
  state.user ? state.user.displayName : state.user;

export const emailSelector = (state) =>
  state.user ? state.user.email : state.user;

export const providerDataSelector = (state) =>
  state.user ? state.user.providerData : state.user;

export const anonSelector = (state) =>
  state.user ? state.user.isAnonymous || false : state.user;

export const isAnonSelector = (state) => state.user?.isAnonymous;
