import React from 'react'
import styled from '@emotion/styled'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BaseButton from './Button'
import Text from './Text'
import UserCanvasPlaceholder from './UserCanvasPlaceholder'
import { removeCanvasFromCollection, deleteCanvas } from '../firebase'
import { userIdSelector } from '../../auth/authSelectors'
import useCanvasRetrieval from '../hooks/useCanvasRetrieval'
import { devices } from '../constants/devices'

const UserCanvas = ({ userCanvas }) => {
  const [canvas] = useCanvasRetrieval(userCanvas.id)
  const userId = useSelector(userIdSelector)
  const history = useHistory()
  const location = useLocation()

  const goToCanvas = () => {
    history.push(`canvas/${userCanvas.id}`)
  }

  const handleShare = (e) => {
    e.stopPropagation();
    const host = window.location.host;
    const canvasId = userCanvas.id;
    const canvasName = canvas.name;
    history.push({
      pathname: `canvas/${canvasId}/share`,
      state: { background: location, link: `${host}/canvas/${canvasId}/sh`, canvasName }
    });
  }

  const handleOpen = (e) => {
    e.stopPropagation();
    goToCanvas()
  }

  const handleRemove = (e) => {
    e.stopPropagation();
    removeCanvasFromCollection(userCanvas.id, userId)
  }

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteCanvas(userCanvas.id)
  }

  return userCanvas.id ? (
    <UserCanvasBox onClick={goToCanvas}>
      <UserCanvasHeader>
        <HeaderTitle>
        <Text>{canvas?.name || ``}</Text>
        </HeaderTitle>
        <HeaderActions>
          {userCanvas.shared ? (
            <Button
              secondary
              onClick={handleRemove}
            >
              remove
            </Button>
          ) : (
            <Button secondary onClick={handleDelete}>
              delete
            </Button>
          )}
          <Button 
            secondary 
            onClick={handleShare}>
              share
          </Button>
          <Button secondary onClick={handleOpen}>open</Button>
        </HeaderActions>
      </UserCanvasHeader>
      <UserCanvasContent>
        <TextType type="body">{getCanvasTypeFormatted(canvas?.type)}</TextType>
      </UserCanvasContent>
    </UserCanvasBox>
  ) : (
    <UserCanvasPlaceholder />
  )
}

const HeaderTitle = styled.div({
  display: 'flex',
})

const HeaderActions = styled.div({
  display: 'flex',
  alignItems: 'center'
})

const getCanvasTypeFormatted = (type) => {
  const typeLower = type?.toLowerCase()
  switch (typeLower) {
    case 'lean':
      return 'lean canvas'
    case 'featurepriority':
      return 'feature priority canvas'
    case 'businessmodel':
      return 'business model canvas'
    case 'feature':
      return 'feature canvas'
    case 'usercentred':
      return 'user centred design canvas'
    default:
      return 'custom canvas'
  }
}

const UserCanvasBox = styled.div({
  cursor: 'pointer',
  padding: 10,
  marginTop: 10,
  border: '1px solid black',
  display: 'flex',
  flexDirection: 'column',
  [`${devices.laptop}`]: {
    padding: 20,
    marginTop: 20,
  },
})

const UserCanvasContent = styled.div({
  display: 'flex',
  marginTop: '0.3125rem',
  [`${devices.laptop}`]: {
    marginTop: '0.625rem',
  },
})

const TextType = styled(Text)({
  whiteSpace: 'nowrap',
  paddingRight: '0.625rem',
  minWidth: 'auto',
  [`${devices.laptop}`]: {
    paddingRight: '1.25rem',
  },
})

const UserCanvasHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems:'center'
})

const Button = styled(BaseButton)(() => ({
  marginRight: 10,
}))

export default UserCanvas
